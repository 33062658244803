export const menuItems = [
         {
           name: "menu",
           label: "Menu",
           items: [
            //  { name: "video", label: "Produktvideo" },
             { name: "integration", label: "Integration" },
             { name: "reference", label: "Referenzen" },
             { name: "team", label: "Team" },
             { name: "faq", label: "FAQ" },
             { name: "contact", label: "Kontakt" },
            //  {
            //    name: "careers",
            //    label: "Careers",
            //    items: [
            //      { name: "career", label: "Career" },
            //      { name: "career-details", label: "Career Details" },
            //    ],
            //  },
            //  {
            //    name: "utility",
            //    label: "Utility",
            //    items: [
            //      { name: "pricing", label: "Pricing & Plan" },
            //      { name: "contact", label: "Contact" },
            //      { name: "faq", label: "FAQ" },
            //      { name: "comingsoon", label: "Coming soon" },
            //      { name: "terms", label: "Terms & Conditions" },
            //      { name: "404", label: "404 Page" },
            //    ],
            //  },
            //  {
            //    name: "account",
            //    label: "Account",
            //    items: [
            //      { name: "signin", label: "Sign In" },
            //      { name: "signin-image", label: "Sign In Image" },
            //      { name: "signup", label: "Sign Up" },
            //      { name: "signup-image", label: "Sign Up Image" },
            //      { name: "reset-pass", label: "Reset Password" },
            //    ],
            //  },
           ],
         },
       ];
