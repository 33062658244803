import React from "react";
import { Link } from "gatsby";
import { scroller } from "react-scroll";
import imgLW from "../../assets/image/logo-main-white.png";

const Footer = ({ className, ...rest }) => {
  const scrollTo = (id) => {
    scroller.scrollTo(id, {
      duration: 1800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  return (
    <>
      <div className={`bg-charade pt-16 pb-10 ${className}`} {...rest}>
        <div className="container-wide">
          <div className="pb-10 px-5">
            <div className="row align-items-center justify-content-lg-between">
              {/* Brand Logo */}
              <div className="col-sm-5">
                <img
                  className="light-version-logo default-logo"
                  src={imgLW}
                  alt=""
                />
              </div>
              {/* End Brand Logo */}
              {/* Menü */}
              <div className="d-xs-block d-sm-flex col-lg-6 col-md-10 md-row justify-content-sm-between mt-8 mt-lg-1 pr-5">
                {/* <div
                  className="d-xs-block text-white gr-hover-text-konzierge-blue-3 mt-4 mt-lg-0 cursor-pointer"
                  onClick={() => scrollTo("video")}
                >
                  Video
                </div> */}
                <div
                  className="d-xs-block text-white gr-hover-text-konzierge-blue-3 mt-4 mt-lg-0 cursor-pointer"
                  onClick={() => scrollTo("integration")}
                >
                  Integration
                </div>
                <div
                  className="d-xs-block text-white gr-hover-text-konzierge-blue-3 mt-4 mt-lg-0 cursor-pointer"
                  onClick={() => scrollTo("reference")}
                >
                  Referenzen
                </div>
                <div
                  className="d-xs-block text-white gr-hover-text-konzierge-blue-3 mt-4 mt-lg-0 cursor-pointer"
                  onClick={() => scrollTo("team")}
                >
                  Team
                </div>
                <div
                  className="d-xs-block text-white gr-hover-text-konzierge-blue-3 mt-4 mt-lg-0 cursor-pointer"
                  onClick={() => scrollTo("faq")}
                >
                  FAQ
                </div>
              </div>
            </div>
            <div className="row align-items-end justify-content-lg-start">
              {/* End Menü */}
              {/* Company Info */}
              <div className="col-lg-3 mt-16 justify-content-lg-start">
                <div className="text-white">Thesmo Legal Tech Solutions</div>
                <div className="text-white">Schönbrunner Straße 20/8</div>
                <div className="text-white">1050 Wien, Österreich</div>
              </div>
              <div className="col-lg-3 mt-16 justify-content-lg-start">
                <div className="text-white">+43 (0) 660 4422111</div>
                <div className="text-white">office@konzierge.at</div>
              </div>
              {/* End Company Info */}
              {/* Newsletter */}
              {/* <div className="col-lg-5 col-md-6 col-sm-8 col-xs-10">
                <div>
                  <div className="shadow-3 rounded-4">
                    <div className="text-white">Newsletter</div>
                    <form action="/">
                      <div className="newsletter-3">
                        <div className="form-group mr-3">
                          <label className="mb-0" htmlFor="mail">
                            <i className="icon icon-email-84 mb-0" />
                          </label>
                          <input
                            id="mail"
                            type="email"
                            className="form-control"
                            placeholder="E-Mail"
                          />
                        </div>
                        <button className="btn btn-dodger-blue-2 rounded-5">
                          Anmelden
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div> */}
              {/* End Newsletter */}
            </div>
          </div>
          {/* TODO: make sites */}
          <div className="border-top border-default-color dark-mode-texts d-flex justify-content-center justify-content-sm-between flex-column flex-sm-row flex-wrap pt-9 px-5">
            <div>
              <ul className="d-xs-block d-sm-flex align-items-start list-unstyled mb-5 mb-sm-0">
                <li>
                  <Link
                    to="/impressum"
                    className="text-white gr-hover-text-konzierge-blue-3 font-size-5 pr-12"
                  >
                    Impressum
                  </Link>
                </li>
                <li>
                  <Link
                    to="/datenschutz"
                    className="text-white gr-hover-text-konzierge-blue-3 font-size-5 pr-12"
                  >
                    Datenschutz
                  </Link>
                  <Link
                    to="/rechtliches"
                    className="text-white gr-hover-text-konzierge-blue-3 font-size-5 pr-12"
                  >
                    Rechtliches
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              {/* TODO: Social Media Links */}
              <ul className="d-flex align-items-center list-unstyled mb-0">
                {/* <li>
                  <Link
                    to="/#"
                    className="pl-5 text-white gr-hover-text-konzierge-blue-3 font-size-5 pr-6"
                  >
                    <i className="fab fa-twitter" />
                  </Link>
                </li>
                <li>
                  <Link
                    to="/#"
                    className="pl-5 text-white gr-hover-text-konzierge-blue-3 font-size-5 pr-6"
                  >
                    <i className="fab fa-google" />
                  </Link>
                </li> */}
                <li>
                  <a
                    href="https://www.linkedin.com/company/konzierge/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white gr-hover-text-konzierge-blue-3 font-size-8 pr-6"
                  >
                    <i className="fab fa-linkedin" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
